
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import { DocumentAccess, DocumentFolder, DocumentType, IndexDocumentPayload } from "@/store/modules/document.store";
import { PartnerRequest } from "@/store/modules/partner-request.store";
import { PartnerType } from "@/store/modules/partner.store";
import { FormFile } from "@/components/forms/index";
import { bus } from "@/main";

@Component({
    components: {
        FormFile,
        documentCard: () => import("@/components/document/card-old.vue"),
        updateDocumentModal: () => import("@/components/modals/update-document.vue"),
        previewMediaModal: () => import("@/components/modals/preview-media.vue"),
        documentPlaceholder: () => import("@/components/document/placeholder.vue"),
        documentUpload: () => import("@/components/document/upload.vue"),
        documentFile: () => import("@/components/document/file.vue"),
    },
})
export default class RequestDocuments extends Vue {
    @Action("document/create") createDocument!: DocumentCreate;
    @Action("document/index") indexDocuments!: (payload: IndexDocumentPayload) => Promise<Document[]>;

    @Prop({ required: true }) partnerRequest!: PartnerRequest;

    @Prop({ default: false }) extra!: boolean;

    documents: Document[] = [];

    bus = bus;

    get folder() {
        if (this.extra) {
            return false;
        }

        switch (this.partnerRequest?.partner.type) {
            case PartnerType.photo:
                return DocumentFolder.pictures;
            case PartnerType.epcInspector:
            case PartnerType.electricityInspector:
            case PartnerType.fuelOilTankInspector:
            case PartnerType.asbestosInspector:
            case PartnerType.comboEpcInspector:
                return DocumentFolder.inspections;
        }
        return DocumentFolder.varia;
    }

    get placeholders() {
        if (this.extra) {
            return this.extraPlaceholders();
        }

        return this.partnerPlaceholders();
    }

    extraPlaceholders() {
        switch (this.partnerRequest?.partner.type) {
            case PartnerType.asbestosInspector:
                return [DocumentType.asbestosProjectAssignmentAgreement, DocumentType.asbestosProjectDocumentationConstructionPlans, DocumentType.asbestosProjectDocumentationOther, DocumentType.asbestosProjectDocumentationPostIntervention];
        }
        return [];
    }

    partnerPlaceholders() {
        switch (this.partnerRequest?.partner.type) {
            case PartnerType.photo:
                return [];
            case PartnerType.epcInspector:
                return [DocumentType.epc];
            case PartnerType.electricityInspector:
                return [DocumentType.elektrischeKeuring];
            case PartnerType.fuelOilTankInspector:
                return [DocumentType.stookolietankKeuring];
            case PartnerType.asbestosInspector:
                return [DocumentType.asbestInventaris];
            case PartnerType.comboEpcInspector:
                return [DocumentType.epc, DocumentType.elektrischeKeuring, DocumentType.stookolietankKeuring];
        }
        return DocumentFolder.varia;
    }

    async mounted() {
        this.indexDocuments({ per_page: 999, q: { and: { activity_id: Number(this.partnerRequest.activity.id) } } }).then((documents) => {
            this.documents = documents;
        });
    }

    get filteredDocuments() {
        if (this.partnerRequest?.partner.type === PartnerType.photo) {
            return this.documents;
        }

        return this.documents.filter((document: Document) => {
            return document.type ? (this.placeholders ?? []).includes(document.type) : false;
        });
    }

    async handleFileChange(medias: Media[]) {
        try {
            let promises = [];

            for (let media of medias) {
                promises.push(this.uploadDocument(media));
            }

            await Promise.all(promises);

            this.$toast.open({ message: "Documenten toegevoegd", type: "success" });
        } catch (e) {
            this.$toast.open({ message: "Er ging iets mis tijdens het oploaden van de bestanden", type: "error" });
        }
    }

    handleFilePreview(file: Document) {
        bus.$emit("show-preview-media", file.media[0]);
    }

    async uploadDocument(media: Media) {
        return this.createDocument({
            name: media.type ? (this.$t(`views.documents.placeholders.${media.type}`) as string) : media.name,
            activity_id: this.partnerRequest!.activity.id,
            partner_id: this.partnerRequest!.partner.id,
            type: media.type ?? null,
            meta: {
                folder: media.folder ?? DocumentFolder.varia,
                access: [DocumentAccess.internal],
            },
            media: [{ uuid: media.uuid, name: media.name }],
        });
    }
}
